
import { Component, Vue } from "vue-property-decorator";
import OrderApi from "@/services/api/order";
import { AxiosError, AxiosResponse } from "axios";
import Order, { SerializedOrder } from "@/models/order";
import OrderReceipt from "@/components/OrderReceipt.vue";
import { mdiDownload } from "@mdi/js";
import { Getter } from "vuex-class";
import { addAnalyticsEvent, ensureAuthenticated } from "@/plugins/firebase";
import OrderCard from "@/components/OrderCard.vue";
import OrderCardSkeleton from "@/components/OrderCardSkeleton.vue";
import OrderQuantity from "@/components/OrderQuantity.vue";
import { ApiResponse } from "@/services/api/axios";
@Component({
    components: { OrderQuantity, OrderCardSkeleton, OrderCard, OrderReceipt },
})
export default class OrdersIndex extends Vue {
    loadingOrders: boolean = true;
    orders: Array<Order> = [];
    downloadIcon: string = mdiDownload;

    @Getter("authToken") authToken!: string;

    headers = [
        {
            text: "Order ID",
            value: "orderReference",
        },
        {
            text: "Ordered At",
            value: "createdAtTimestamp",
        },
        {
            text: "DSTV Package",
            value: "dstvPackageName",
        },
        {
            text: "Amount Paid",
            value: "paymentAmount",
        },
        {
            text: "Status",
            value: "orderStatus",
        },
        { text: "Details", value: "data-table-expand" },
    ];

    mounted() {
        ensureAuthenticated()
            .then(() => {
                this.loadOrders();
            })
            .catch(() => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.ERROR,
                    "We're having trouble authenticating your account. Please try again later"
                );
            });
    }

    downloadPDFClicked() {
        addAnalyticsEvent("download_pdf_button_clicked");
    }
    loadOrders() {
        OrderApi.index()
            .then((response: AxiosResponse) => {
                this.orders = response.data.data
                    .map((order: SerializedOrder) => {
                        return new Order(order);
                    })
                    .sort((a: Order, b: Order) => {
                        return a.createdAtTimestamp < b.createdAtTimestamp
                            ? 1
                            : a.createdAtTimestamp > b.createdAtTimestamp
                            ? -1
                            : 0;
                    });
                this.loadingOrders = false;
            })
            .catch(this.handleAxiosError);
    }

    emitErrorNotification(error: Error) {
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
