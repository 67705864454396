
import { Vue, Component, Prop } from "vue-property-decorator";
import Order from "@/models/order";
import { addAnalyticsEvent } from "@/plugins/firebase";
import { mdiDownload } from "@mdi/js";

@Component
export default class DownloadReceiptButton extends Vue {
    @Prop({ required: true, type: Object }) order!: Order;

    downloadIcon: string = mdiDownload;

    downloadPDFClicked() {
        addAnalyticsEvent("download_receipt_pdf_button_clicked", {
            orderId: this.order.orderId,
        });
    }
}
